@tailwind base;
@tailwind components;
@tailwind utilities;

html,
* {
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif;

  .pt-4-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem !important;
  }

  .table-row {
    cursor: pointer;
  }
}
